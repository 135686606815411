<template>
    <div class="contain" id="Employment" v-infinite-scroll="load" :infinite-scroll-disabled="finish" :infinite-scroll-immediate="false">
        <searchBar @search="search"></searchBar>
        <div class="title bgf flex">
            <div class="fs_16 color_333 flex_start">
                <span>任务列表</span>
                <span class="badge">{{ total || 0 }}</span>
            </div>
            <div class="flex_c right">
                <div @click="authPath([], postTask)" class="btn flex_cen">
                    <img src="@/assets/imgs/icon/post-icon.png" class="post-icon" alt="" />
                    <span class="fs_14 color">发布新任务</span>
                </div>
                <!-- <div class="btn fs_14 flex_cen color import-batch" @click="authPath([], bulkImport)">
                    <p class="import-icon "></p>批量指定合作方 <span class="tip">NEW</span>
                </div> -->
            </div>
        </div>
        <div class="list" v-if="list.length != 0">
            <div class="item bgf mt_10" v-for="(item, index) in list" :key="index">
                <div class="bottom_link top">
                    <div class="flex">
                        <div class="flex_c item_title flex_1">
                            <!-- <el-popover placement="bottom" title="" :width="180" trigger="manual" :visible="item.qrcodeVisible" v-if="item.agreement">
                                <template #reference>
                                    <div class="fs_16 bold color_333 mr_10 one proview pointer" style="max-width:300px;" @mouseenter="proview(item)" @mouseleave="item.qrcodeVisible=false">
                                        {{ item.reward_name }}
                                    </div>
                                </template>
                                <div class="qrcode-main">
                                    <div :ref="'jobQRcode'+item.id" :id="'jobQRcode'+item.id"></div>
                                    <p class="fs_14 center color_333 mt_5">微信扫一扫</p>
                                    <img src="../../assets/imgs/layout/zhilu.png" class="logo-thumb" alt="">
                                </div>
                            </el-popover> -->
                            <div class="fs_16 bold color_333 mr_10 one pointer" style="max-width:300px;">
                                {{ item.reward_name }}
                            </div>
                            <p class="fs_12 color_999 mr_15">任务编号 {{ item.reward_no }}</p>
                        </div>
                        <div class="flex_end center_body" v-if="item.agreement">
                            <router-link :to="`/employment/task/${item.id}/${item.reward_name}?tab=0`" v-if="false">
                                <div class="fs_12 wait flex_cen ml_20 pointer">
                                    <span class="color fs_20 mr_5">{{ item.number_status.status0 }}人</span>
                                    <span class="sub_color">待审核</span>
                                </div>
                            </router-link>
                            <!-- <router-link :to="`/employment/task/${item.id}/${item.reward_name}?tab=2`"> -->
                                <div class="fs_14 wait flex_cen ml_20 pointer" @click="taskList(item, 2)">
                                    <span class="info_color mr_5">{{ item.number_status.status2 }}人</span>
                                    <span class="info_color">已结束</span>
                                </div>
                            <!-- </router-link> -->
                            <!-- <router-link :to="`/employment/task/${item.id}/${item.reward_name}?tab=1`"> -->
                                <div class="fs_14 wait flex_cen ml_20 pointer" @click="taskList(item, 1)">
                                    <span class="color mr_5 bold">{{ item.number_status.status1 }}人</span>
                                    <span class="info_color">进行中</span>
                                </div>
                            <!-- </router-link> -->
                        </div>
                        <router-link :to="`/employment/upload-protocol/${item.id}`" class="btn fs_14 center color_666 upload-btn" v-else>
                            完善任务协议
                        </router-link>
                    </div>
                    <div class="color_666 fs_14 one mt_10">
                        {{ item.desc }}
                    </div>
                </div>
                <div class="bottom fs_14">
                    <el-row>
                        <el-col :span="7">
                            <span class="label sub_color">计价方式：</span>
                            <span class="color_333 fs_16">{{ item.settle_info }}</span>
                        </el-col>
                        <el-col :span="5">
                            <span class="label sub_color">结算周期：</span>
                            <span class="color_333">{{ item.price_type }}</span>
                        </el-col>
                        <el-col :span="5">
                            <span class="label sub_color">结算形式：</span>
                            <span class="color_333">{{ item.settle_type }}</span>
                        </el-col>
                        <el-col :span="5">
                            <span class="label sub_color">接单主体：</span>
                            <span class="color_333">{{ item.role_limit_text }}</span>
                        </el-col>
                    </el-row>
                    <el-row class="mt_10">
                        <el-col :span="7">
                            <span class="label sub_color">起止时间：</span>
                            <span class="color_333">{{ item.publish_date }}</span>
                        </el-col>
                        <el-col :span="5">
                            <span class="label sub_color">联系人 ：</span>
                            <span class="color_333">{{ item.publish_contacts }}</span>
                        </el-col>
                        <el-col :span="5">
                            <span class="label sub_color">联系方式：</span>
                            <span class="color_333">{{ item.publish_tel }}</span>
                        </el-col>
                        <el-col :span="5">
                            <span class="label sub_color">发布人：</span>
                            <span class="color_333">{{ item.publish_user_name }}</span>
                        </el-col>
                    </el-row>
                </div>
                <div class="top_link operate flex">
                    <el-switch v-model="item.status" active-color="#1890FF" inactive-color="#D1DEE9" :inactive-text="item.status == 0 ? '不显示' : ''" :active-text="item.status == 1 ? '显示' : ''" :active-value="1" :inactive-value="0" @change="statusChange(item.id, item.status, index)" v-if="item.agreement"></el-switch>
                    <div class="flex_end flex_1">
                        <p class="btn fs_14 center color_666" @click="showQrcode(item)">
                            <i class="el-icon-view"></i>
                            查看小程序码
                        </p>
                        <!-- <p class="btn fs_14 center color_666" @click="getDetail(item.id)">
                            <i class="el-icon-view"></i>
                            预览
                        </p> -->
                        <p class="btn fs_14 center color_666" v-if="item.agreement" @click="previewAgr(item)">
                            <i class="el-icon-tickets"></i>
                            查看协议
                        </p>

                        <router-link :to="`/employment/post?id=${item.id}&a=${item.agreement?1:0}&n=${item.number_status.status1}`" class="btn fs_14 center color_666">
                            <i class="el-icon-edit-outline"></i>
                            编辑
                        </router-link>
                        <div class="btn fs_14 center color_666" @click="handleDialog(item, index)" v-if="item.number_status.status1==0">
                            <i class="el-icon-delete"></i>
                            删除
                        </div>
                        <div @click="taskList(item, 1)" class="btn fs_14 center color_666" v-if="item.agreement">
                            <i class="el-icon-document-copy"></i>
                            管理任务
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <empty :text="list.length==0&&keywords?'未找到此任务':'暂无任务列表'" :finish="finish" :length="list.length" v-else>
            <router-link to="/employment/post" class="flex_cen" v-if="!keywords">
                <el-button size="medium" plain>立即发布任务</el-button>
            </router-link>
        </empty>
        <!-- 预览 -->
        <el-dialog v-model="dialogVisible" :lock-scroll="true" width="800px" top="5vh" destroy-on-close>
            <!-- <div class="pdfMain"> -->
            <div id="pdf" ref="print" class="print">
                <div class="flex_cen"><img src="@/assets/imgs/layout/logo.png" class="print-logo" alt=""></div>
                <div class="bgf">
                    <div class="center padding_20 title fs_36">灵活用工新任务</div>
                    <div class="fs_20 color_333 center mb_15"><span class="company-name">{{ companyInfo.company_name }}</span></div>
                    <el-form :model="ruleForm"  :rules="rules" ref="ruleForm" label-position="top" :hide-required-asterisk="true" class="form">
                        <div class="fs_20 color_333 padding_20 border_bottom mb_15 bold mt-50">基本信息</div>
                        <el-row :gutter="20" class="print-item">
                            <el-col :span="24">
                                <el-form-item label="任务标题" prop="reward_name">
                                    <div class="print-text">{{ ruleForm.reward_name }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="任务介绍" prop="desc">
                                    <div v-html="ruleForm.desc" class="textarea"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" class="print-item">
                            <el-col :span="12">
                                <el-form-item label="起止时间" prop="fromTo">
                                    <p class="print-text"><span class="el-icon-date mr_10 color_999 fs_20"></span>{{ ruleForm.fromTo[0] }} <span style="margin:0 15px">—</span> {{ ruleForm.fromTo[1] }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="接单主体" prop="role_limit">
                                    <p class="print-text">{{ ruleForm.role_limit }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="fs_20 color_333 padding_20 padding_lr_0 border_bottom mb_15 bold">报酬约定</div>
                        <el-row :gutter="20" class="print-item">
                            <el-col :span="8">
                                <el-form-item label="报酬" prop="settle_money">
                                    <p class="print-text">{{ ruleForm.settle_money }} 元 / {{ ruleForm.price_method }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'&nbsp'" prop="price_type" class="not_spot">
                                    <p class="print-text">{{ ruleForm.price_type }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'&nbsp'" prop="settle_type" class="not_spot">
                                    <p class="print-text">{{ ruleForm.settle_type }}结算</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="fs_20 bold color_333 padding_20 padding_lr_0 border_bottom mb_15">
                            联系方式
                        </div>
                        <el-row :gutter="20" class="print-item">
                            <el-col :span="12">
                                <el-form-item label="联系人" prop="publish_contacts">
                                    <p class="print-text">{{ ruleForm.publish_contacts }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="联系方式" prop="publish_tel">
                                    <p class="print-text">{{ ruleForm.publish_tel }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <div class="utils flex_col no-print">
                <el-button type="primary" size="medium" @click="handlePrint">打 印</el-button>
                <el-button type="primary" size="medium" :loading="loading" @click="toPdf">导出PDF</el-button>
                <el-button type="primary" size="medium" @click="dialogVisible=false">取 消</el-button>
            </div>
            <!-- </div> -->
        </el-dialog>
        <!-- 协议 -->
        <el-dialog v-model="agreementVisible" :lock-scroll="true" width="600px" top="5vh" custom-class="agreementFile"  destroy-on-close :show-close="false">
            <div v-for="(item, index) in agreementFile" :key="index">
                <div v-if="item.isSign" style="position: relative;">
                    <img :src="item.url" class="img-item" alt="协议文件">
                    <img src="../../assets/imgs/signTmp.png" class="signTmp" :style="{top:sign_location[1]-25+'px',left:sign_location[0]-50+'px'}" alt="">
                </div>
                <img :src="item.url" class="img-item" alt="协议文件" v-else>
            </div>
            <div class="utils flex_col">
                <el-button type="primary" size="medium" @click="updateAgr" class="btn">重新上传</el-button>
                <el-button type="primary" size="medium" @click="agreementVisible=false" class="btn mt_10">关 闭</el-button>
            </div>
        </el-dialog>
        <!-- 小程序二维码 -->
        <el-dialog v-model="qrCodeVisible" :lock-scroll="true" width="400px" top="20vh" custom-class="agreementFile"  destroy-on-close :show-close="false">
            <!-- <img :src="weChatQrCodeUrl" class="img-item" alt="微信小程序二维码" v-else> -->
            <div class="qrcode-main padding_20">
                <div ref="dialogQrcodeContent" id="dialogQrcodeContent"></div>

                <!-- <div :ref="'jobQRcode'+item.id" :id="'jobQRcode'+item.id"></div> -->
                <p class="fs_16 center color_333 mt_10">微信扫一扫</p>
                <img src="../../assets/imgs/layout/zhilu.png" class="logo-thumb" style="width:68px;height:68px" alt="">
            </div>
            <div class="utils flex_col">
                <el-button type="primary" size="medium" @click="qrCodeVisible=false" class="btn mt_10">关 闭</el-button>
            </div>
        </el-dialog>
        <el-backtop :bottom="100"></el-backtop>
    </div>
</template>

<script>
import htmlToPdf from 'td-htmltopdf'
import print from 'td-print'
import QRCode from 'qrcodejs2'
import { mapGetters } from "vuex"
import { s_setItem } from "@/common/cache"
export default {
	name: "Employment",
	data() {
		return {
			id: '',
			dialogVisible: false,
			loading: false,
			list: [],
			page: 1,
			limit: 10,
			finish: false,
			total: 0,
			keywords: "",
			qrCodeVisible: false,
			weChatQrCodeUrl: '',
			agreementVisible: false,
			agreementFile: [],
			isSign: 0,
			sign_location: [],
			ruleForm: {
				reward_name: "",
				self_limit: 0,
				desc: "",
				fromTo: [],
				role_limit: "",
				settle_money: "",
				price_method: "",
				price_type: "",
				settle_type: "",
				publish_contacts: "",
				publish_tel: ""
			}
		}
	},
	created() {
		this.getList()
	},
	computed: {
		...mapGetters({
			companyInfo: "getCompanyInfo"
		})
	},
	methods: {
		toPdf() {
			window.scrollTo(0, 0)
			this.loading = true
			htmlToPdf(this.$refs.print, this.ruleForm.reward_name)
			setTimeout(() => {
				this.loading = false
			}, 1000)
		},
		handlePrint() {
			print(this.$refs.print, {
				paging: true,
				style: `<style>.print{transform: scale(1) !important;margin-top:40px;padding:0 !important;}</style>`
			})
		},
		getDetail(id) {
			this.$axios({
				url: `/ent/v3/reward/${id}`,
				method: "GET"
			}).then(res => {
				if (res.code === 200) {
					switch (res.data.role_limit) {
					case 1:
						res.data.role_limit = '个体工商户'
						break
					case 2:
						res.data.role_limit = '个人独资企业'
						break
					case 4:
						res.data.role_limit = '自然人'
						break
					}
					res.data.desc = res.data.desc.replaceAll("\n", "<br />")
					res.data.desc = res.data.desc.replaceAll("\r", "<br />")
					this.ruleForm = res.data
					this.ruleForm.fromTo = [ res.data.publish_time, res.data.deadline ]
					this.dialogVisible = true
				}
			})
		},
		load() {
			this.page++
			this.getList()
		},
		getList() {
			this.$axios({
				url: "/ent/v3/reward",
				method: "GET",
				data: {
					page: this.page,
					limit: this.limit,
					keywords: this.keywords
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					res.data.list.forEach(item=>{
						item.qrcodeVisible = false
					})
					this.list = this.list.concat(res.data.list)
					this.list = this.arrFilter(this.list)
					this.list.sort((a, b)=> b.id-a.id)
					this.total = res.data.total
					this.finish = this.list.length >= res.data.total
				} else {
					this.finish = true
				}
			})
		},
		showQrcode(item) {
			this.id = item.id
			this.qrCodeVisible = true

			this.$nextTick(() => {
				document.getElementById('dialogQrcodeContent').innerHTML = ""
				new QRCode(this.$refs.dialogQrcodeContent, {
					text: `https://api.zhiluinfo.com/taskDetail?id=${this.id}`,
					width: 400,
					height: 400,
					colorDark: "#000000", //二维码颜色
					colorLight: "#ffffff", //二维码背景色
					correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
				})
			})
		},
		proview(item) {
			document.getElementById("jobQRcode"+item.id).innerHTML = ""
			this.$nextTick(() => {
				new QRCode(this.$refs['jobQRcode'+item.id], {
					text: `https://api.zhiluinfo.com/taskDetail?id=${item.id}`,
					width: 200,
					height: 200,
					colorDark: "#000000", //二维码颜色
					colorLight: "#ffffff", //二维码背景色
					correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
				})
				item.qrcodeVisible = true
				this.$forceUpdate()
			})
		},
		statusChange(id, status, index) {
			this.$axios({
				url: `/ent/v3/reward/status/${id}`,
				method: 'PUT',
				data: {
					status
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.list[index].status = status
				} else {
					this.list[index].status = Number(!status)
				}
			})
		},
		handleDialog(item, index) {
			this.$confirm(`此操作将永久删除 <span class="color_000 bold">${item.reward_name}</span> 任务，是否继续?`, '提示', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			}).then(() => {
				this.$axios({
					url: `/ent/v3/reward/${item.id}`,
					method: 'DELETE',
					shake: true
				}).then(res => {
					if (res.code === 200) {
						this.list.splice(index, 1)
						this.total--
						this.$message.success(`${item.reward_name}任务已删除`)
					}
				})
			})
		},
		search(v) {
			this.keywords = v
			this.page = 1
			this.list = []
			this.finish = false
			this.getList()
		},
		postTask() {
			this.$router.push("/employment/post")
		},
		bulkImport() {
			this.$router.push('/import-batch')
		},
		previewAgr(item) {
			let agreement = item.agreement.upload_img
			let _agrs = []
			let isSign = false
			let signIndex = item.agreement.upload_img_sign

			agreement.forEach((url, id)=>{
				isSign = signIndex === id
				_agrs.push({ url, isSign, id })
			})

			this.agreementFile = _agrs
			this.sign_location = item.agreement.sign_location
			this.id = item.id
			this.agreementVisible = true
		},
		updateAgr() {
			this.agreementVisible = false
			s_setItem('_agrs', this.agreementFile)
			this.$router.push(`/employment/upload-protocol/${this.id}`)
		},
		taskList(item, tab) {
			s_setItem('_taskDetail', item)
			this.$router.push(`/employment/task/${item.id}/${item.reward_name}?tab=${tab}`)
		}
	}
}
</script>

<style lang="less" scoped>
.utils {
    position: absolute;
    right: -125px;
    top: 0;
    z-index: 99;
    .el-button {
        width: 110px;
        height: 42px;
        margin: 0 0 10px;
        font-size: 14px;
        background: #fff;
        color: #333;
        border-color: #fff;
        transition: all 0.2s ease;
        &:hover {
            background: #1890ff;
            color: #fff;
            border-color: #1890ff;
            transition: all 0.2s ease;
        }
    }
}
.signTmp {
    width: 200px;
    height: 100px;
    position: absolute;
    z-index: 3;
}

.edit {
    background-color: #ededed;
    color: #999;
    border-radius: 0px 0px 15px 15px;
    text-align: center;
    width: 66px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    top: 0;
    right: 110px;
    transition: all 0.2s ease;
    z-index: 9;
    &:hover {
        background: #5ab0ff;
        color: #fff;
        transition: all 0.2s ease;
    }
    &.pdf {
        right: 30px;
    }
}
.disable {
    background-color: #ededed;
    color: #999;
}
.color_red {
    color: #cc1414;
}
.ml_20 {
    margin-left: 45px;
}
.title {
    padding: 19px 38px 20px 54px;
    border-radius: 2px;

    .post-icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }
}
.qrcode-main {
    position: relative;
    .logo-thumb {
        position: absolute;
        width: 36px;
        height: 36px;
        border-radius: 6px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -84%);
        z-index: 999;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    }
}
.proview {
    text-decoration: underline;
}
.list {
    .item {
        .top {
            padding: 30px 40px 20px 30px;
            .center_body {
                width: 280px;
            }

        }
        .bottom {
            position: relative;
            padding: 20px 40px 30px 30px;
            .label {
                width: 80px;
                display: inline-block;
            }
        }
        .operate {
            padding: 20px 40px 20px 30px;
        }
        .btn {
            min-width: 100px;
            height: 32px;
            padding: 0 15px;
            line-height: 32px;
            border: 1px solid #ccc;
            border-radius: 2px;
            margin-left: 20px;
            transition: all .2s ease;
            &:hover {
                color: #fff;
                background: #1890ff;
                border-color: #1890ff;
                transition: all .2s ease;
            }
            &.upload-btn {
                color: #1890ff;
                border-color: #1890ff;
                &:hover {
                    color: #fff;
                }

            }
        }
    }
    /deep/.item {
        border-radius: 2px;
        .el-switch {
            margin-right: 20px;
        }
        .el-switch__core {
            width: 100px !important;
            height: 36px;
            border-radius: 36px;
            transform: scale(0.89);
        }
        .el-switch__core .el-switch__action {
            width: 32px;
            height: 32px;
        }
        .el-switch.is-checked .el-switch__core .el-switch__action {
            margin-left: -33px;
        }
        .el-switch__label--left {
            left: 40px;
            position: absolute;
            z-index: 4;
            color: #fff;
        }
        .el-switch__label--right {
            margin-left: 20px;
            // left: -98px;
            position: absolute;
            z-index: 4;
            color: #fff;
        }
        .require {
            padding: 20px 40px;
        }
        .proview {
            text-decoration: underline;
        }
    }
}

.import-batch {
    position: relative;
    transition: color 0.2s ease;
    color: #1890ff !important;
    transition: color 0.2s ease;
    .import-icon {
        background: url(../../assets/imgs/icon/import-icon1.png);
        transition: all 0.2s ease;
    }
}

</style>

<style lang="less">
#pdf {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 99999;
    padding: 20px 40px 30px;
    .padding_20 {
        padding: 20px 0;
    }
    .print-item {
        page-break-after: always;
    }
    .company-name {
        padding: 10px 20px;
        border-radius: 4px;
        border: 1px solid #e4e7ed;
    }
    .textarea,
    .print-text {
        background-color: rgba(249, 249, 249, 0.5) !important;
        font-size: 16px;
        color: #000;
        padding: 5px 15px;
        border: 1px solid #f6f7f8;
        border-radius: 4px;
        box-sizing: border-box;
    }
    .border_bottom {
        border-bottom: 1px dashed #e4e7ed;
    }
    .el-form-item__label {
        font-size: 20px;
    }
    .title {
        color: #333;
        font-weight: bold;
    }
    .print-logo {
        width: 100px;
        height: auto;
        position: absolute;
        left: 40px;
        top: 40px;
        z-index: 9;
    }
}
@media print {
    #pdf {
        .mt-50 {
            margin-top: 50px !important;
        }
        .title {
            font-size: 54px !important;
            margin-bottom: 15px !important;
        }
        .border_bottom {
            font-size: 30px !important;
            margin-top: 20px;
        }
        .company-name {
            font-size: 30px !important;
            margin-bottom: 50px !important;
        }
        .textarea,
        .print-text {
            font-size: 26px !important;
            padding: 15px 25px;
            margin-top: 20px;
            line-height: 1.5;
            background-color: rgba(249, 249, 249, 0.5) !important;
        }
        .el-form-item__label {
            font-size: 30px !important;
            margin-top: 20px;
        }
        .print-logo {
            width: 120px;
            left: 0;
        }
    }
}

</style>

